<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="550px"  
  >
    <v-card>
      <v-card-title class="colorCustom1 justify-center white--text">ESCANEÁ EL QR DESDE TU CELULAR.</v-card-title>
      <v-card-title class="justify-center mt-14 mb-8">          
        <div>
          <qrcode-vue v-if="url" :value="url" size="350" level="H" />
        </div>
      </v-card-title>    
      <v-card-actions class="justify-center" align="center">   
          <botonVolver prevent="true"  @backPage="show = false" class="ml-2 mr-2"/>           
          <botonFinalizar class="ml-2 mr-2"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import qrcodeVue from 'qrcode.vue'

  export default {
    components: {
      qrcodeVue,
      botonFinalizar: () => import('./botonFinalizar.vue'),
    },
    data () {
      return {
        url: '',       
      }
    },
    props: {
      value: Boolean,
    }, 
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
    methods: {
      setForm: function (url) {
        this.url = url
      },
    },
  }
</script>